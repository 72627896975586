<template>
  <loader-content v-if="loading" />

  <section v-else>
    <div class="d-flex align-center mb-4">
      <h1 class="text-h5">
        <span class="work-sans">Configurações da plataforma</span>
      </h1>
    </div>

    <v-card v-for="(item, i) in _configs" class="shadow mb-4" :key="i">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title> {{ item.title }} </v-list-item-title>

          <v-list-item-subtitle>
            {{ item.subtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn color="primary" text small @click="submit(item.action)">
            alterar
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card>

    <ChangeUserMaster ref="userMaster" @success="getConfigs()" />

    <ChangeMinDeposit ref="minDeposit" @success="getConfigs()" />

    <ChangeWithdrawRate ref="withdrawRate" @success="getConfigs()" />
  </section>
</template>

<script>
import { request } from "@/services";
import { currencyMask } from "@/utils";
import ChangeUserMaster from "./ChangeUserMaster.vue";
import ChangeMinDeposit from "./ChangeMinDeposit.vue";
import ChangeWithdrawRate from "./ChangeWithdrawRate.vue";

export default {
  data() {
    return {
      loading: true,
      configs: {
        minDeposit: 0,
        withdrawRate: 0,
        withdrawRateType: "",
        userMasterName: "",
        userMasterID: null,
      },
    };
  },

  components: {
    ChangeUserMaster,
    ChangeMinDeposit,
    ChangeWithdrawRate,
  },

  beforeMount() {
    this.getConfigs();
  },

  computed: {
    _configs() {
      return [
        {
          title: this.configs.userMasterName,
          subtitle: "Usuário master",
          action: "userMaster",
        },
        {
          title: currencyMask(this.configs.minDeposit) + " BRL",
          subtitle: "Depósito mínimo",
          action: "minDeposit",
        },
        {
          title:
            this.configs.withdrawRateType === "percent"
              ? this.configs.withdrawRate + "%"
              : currencyMask(this.configs.withdrawRate) + " BRL",
          subtitle: "Taxa de saque",
          action: "withdrawRate",
        },
      ];
    },
  },

  methods: {
    async getConfigs() {
      try {
        const payload = {
          method: "getAdminConfigs",
        };

        await request(payload).then((res) => {
          this.configs = {
            minDeposit: res.configs.minDeposit,
            withdrawRate: res.taxasSaque.taxaSaque,
            withdrawRateType:
              res.taxasSaque["taxaSaqueIs%"] === "1" ? "percent" : "monetary",
            userMasterName: res.configs.masteruser.name,
            userMasterID: Number(res.configs.masterUserComissaoID),
          };
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    submit(event) {
      if (event === "userMaster")
        this.$refs.userMaster.open(this.configs.userMasterID);

      if (event === "minDeposit")
        this.$refs.minDeposit.open(this.configs.minDeposit);

      if (event === "withdrawRate")
        this.$refs.withdrawRate.open({
          withdrawRate: this.configs.withdrawRate,
          withdrawRateType: this.configs.withdrawRateType,
        });
    },
  },
};
</script>

<style></style>
