<template>
  <loader-content v-if="loading" />

  <section v-else>
    <div class="d-flex align-center mb-4">
      <h1 class="text-h5">
        <span class="work-sans">Moedas de pagamento</span>
      </h1>

      <v-spacer />

      <v-btn color="primary" depressed rounded text @click="newCurrency()">
        <v-icon small left> mdi-plus </v-icon>
        Nova moeda
      </v-btn>
    </div>

    <v-item-group>
      <v-row>
        <v-col
          v-for="(item, i) in currencies"
          cols="6"
          sm="4"
          md="2"
          xl="1"
          :key="i"
        >
          <v-item>
            <v-card class="shadow" @click="manageCurrency(item)">
              <v-responsive :aspect-ratio="4 / 2">
                <div
                  class="d-flex flex-column fill-height align-center justify-center"
                >
                  <span
                    class="text-body-1 font-weight-bold"
                    :class="item.avaliable ? 'overtext--text' : 'subtext--text'"
                  >
                    {{ item.currency }}
                  </span>

                  <span class="text-caption subtext--text">
                    ({{ item.avaliable ? "ativa" : "inativa" }})
                  </span>
                </div>
              </v-responsive>
            </v-card>
          </v-item>
        </v-col>

        <v-col cols="6" sm="4" md="2" xl="1">
          <v-item>
            <v-card class="shadow" @click="newCurrency()">
              <v-responsive :aspect-ratio="4 / 2">
                <div class="d-flex fill-height align-center justify-center">
                  <v-icon color="primary" large>mdi-plus</v-icon>
                </div>
              </v-responsive>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>

    <NewCurrency ref="newCurrency" @success="getCurrencies()" />

    <ManageCurrency ref="manageCurrency" @success="getCurrencies()" />
  </section>
</template>

<script>
import { request } from "@/services";
import NewCurrency from "./NewCurrency.vue";
import ManageCurrency from "./ManageCurrency.vue";

export default {
  data() {
    return {
      loading: true,
      currencies: [],
    };
  },

  components: {
    NewCurrency,
    ManageCurrency,
  },

  beforeMount() {
    this.getCurrencies();
  },

  methods: {
    async getCurrencies() {
      try {
        const payload = {
          method: "getMoedasAdmin",
        };

        await request(payload).then((res) => {
          this.currencies = res.moedas.map((el) => ({
            id: el.id,
            currency: el.moeda,
            avaliable: el.avaliable === "1",
          }));
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    manageCurrency(data) {
      this.$refs.manageCurrency.open(data);
    },

    newCurrency() {
      this.$refs.newCurrency.open();
    },
  },
};
</script>

<style></style>
