<template>
  <loader-content v-if="loading" />

  <section v-else>
    <div class="d-flex align-center mb-4">
      <h1 class="text-h5">
        <span class="work-sans">Níveis de conta</span>
      </h1>

      <v-spacer />

      <v-btn color="primary" depressed rounded text @click="newLevel()">
        <v-icon small left> mdi-plus </v-icon>
        Novo nível
      </v-btn>
    </div>

    <v-card v-for="(item, i) in _levels" class="shadow mb-4" :key="i">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>

          <v-list-item-subtitle>
            Méta de depósito: {{ currencyMask(item.depositGoal) }} BRL, Taxa:
            {{ item.percentRate }}%
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn color="primary" text small @click="handleEdit(item)">
            editar
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card>

    <CreateLevel ref="createLevel" @success="getLevels()" />

    <EditLevel ref="editLevel" @success="getLevels()" />
  </section>
</template>

<script>
import { request } from "@/services";
import { currencyMask } from "@/utils";
import CreateLevel from "./CreateLevel";
import EditLevel from "./EditLevel";

export default {
  data() {
    return {
      loading: true,
      levels: [],
    };
  },

  components: {
    CreateLevel,
    EditLevel,
  },

  beforeMount() {
    this.getLevels();
  },

  computed: {
    _levels() {
      return this.levels.map((el) => ({
        id: el.id,
        name: el.nome,
        percentRate: el["taxaComissao%"],
        depositGoal: el.metaDepositos,
        deep: el.profundidade,
        percentRenewRate: el["taxaComissaoRenovacao%"],
      }));
    },
  },

  methods: {
    async getLevels() {
      try {
        const payload = {
          method: "getComissoesNivelAdmin",
        };

        await request(payload).then((res) => {
          this.levels = res.niveisComissao;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    newLevel() {
      this.$refs.createLevel.open();
    },

    handleEdit(event) {
      this.$refs.editLevel.open(event);
    },

    currencyMask,
  },
};
</script>

<style></style>
