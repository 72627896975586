<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <div class="pa-6">
        <div class="d-flex mb-4">
          <span class="text-h6">Gerenciar Moeda</span>

          <v-spacer />

          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <loader-content v-if="loading" />

        <div v-else>
          <div class="d-flex">
            <span>Métodos de pagamento</span>

            <v-spacer />

            <v-btn
              color="primary"
              depressed
              rounded
              text
              @click="handleNewMethod()"
            >
              <v-icon small left> mdi-plus </v-icon>
              Novo método
            </v-btn>
          </div>

          <v-list class="pa-0 transparent mb-4" nav dense>
            <v-list-item v-for="(item, i) in paymentMethods" :key="i">
              <v-list-item-action class="mr-4">
                <v-switch
                  :input-value="item.avaliable"
                  color="primary"
                  dense
                  inset
                  value
                  @change="
                    item.avaliable = !item.avaliable;
                    changeMethodStatus(item.id, !item.avaliable);
                  "
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  <span
                    :class="item.avaliable ? 'overtext--text' : 'subtext--text'"
                  >
                    {{ item.name }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click="handleEditMethod(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <div class="d-flex">
            <v-spacer />

            <v-btn
              class="text-none"
              :color="avaliable ? 'overtext' : 'primary'"
              :plain="avaliable"
              :text="avaliable"
              :outlined="avaliable"
              depressed
              rounded
              @click="changeCurrencyStatus()"
            >
              {{ avaliable ? "Desabilitar moeda" : "Habilitar moeda" }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>

    <NewPaymentMethod
      ref="newMethod"
      :contracts="contracts"
      @success="getMethods()"
    />

    <EditPaymentMethod
      ref="editMethod"
      :contracts="contracts"
      @success="getMethods()"
    />
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { displayAlert } from "@/utils";
import NewPaymentMethod from "./NewPaymentMethod.vue";
import EditPaymentMethod from "./EditPaymentMethod.vue";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      currencyID: null,
      avaliable: false,
      paymentMethods: [],
      contracts: [],
    };
  },

  components: {
    NewPaymentMethod,
    EditPaymentMethod,
  },

  methods: {
    async getMethods() {
      try {
        const payload = {
          method: "getRedeMoedaAdmin",
          moedaID: this.currencyID,
        };

        await request(payload).then((res) => {
          this.paymentMethods = res.redes.map((el) => ({
            id: el.id,
            name: el.nomeRede,
            details: el.infoRede,
            isCrypto: el.isCrypto === "1",
            avaliable: el.avaliable === "1",
            contractID: el.idForceContrato,
            proofURL: el.comprovanteLink,
          }));
        });
      } catch (err) {
        this.close();
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    async getContracts() {
      try {
        const payload = {
          method: "listContratos",
        };

        await request(payload).then(async (res) => {
          this.contracts = res.contratos.map((e) => ({
            label: e.nomeContrato,
            value: e.id,
          }));
        });
      } catch (err) {
        console.log(err);
      }
    },

    async changeCurrencyStatus() {
      try {
        const payload = {
          method: "changeMoedaStatus",
          idMoeda: this.currencyID,
          status: this.avaliable ? "0" : "1",
        };

        await request(payload).then(() => {
          this.avaliable = !this.avaliable;
          this.$emit("success");
        });
      } catch (err) {
        console.log(err);
      }
    },

    async changeMethodStatus(id, status) {
      try {
        const payload = {
          method: "changeRedeMoedaStatus",
          redeID: id,
          status: status ? "1" : "0",
        };

        await request(payload);
      } catch (err) {
        console.log(err);
      }
    },

    handleNewMethod() {
      this.$refs.newMethod.open(this.currencyID);
    },

    handleEditMethod(data) {
      this.$refs.editMethod.open({ ...data, currencyID: this.currencyID });
    },

    open(data) {
      this.loading = true;
      this.dialog = true;

      this.currencyID = data.id;
      this.avaliable = data.avaliable;

      this.getMethods();
      this.getContracts();
    },

    close() {
      this.dialog = false;
    },

    displayAlert,
  },
};
</script>

<style lang="scss" scoped></style>
