<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <div class="pa-6">
        <div class="d-flex mb-4">
          <span class="text-h6">Definir usuário master</span>

          <v-spacer />

          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-form v-model="valid" ref="form">
          <div class="mb-4" @keypress.enter.prevent="submit()">
            <custom-input
              ref="id"
              v-model="form.id"
              label="ID do usuário"
              @keypress="onlyNumber($event)"
            />
          </div>
        </v-form>

        <div class="d-flex justify-end">
          <v-btn class="text-none mr-2" plain @click="close()">Cancelar</v-btn>

          <v-btn color="primary" class="text-none" depressed @click="submit()">
            Confirmar
          </v-btn>
        </div>
      </div>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { displayAlert, onlyNumber } from "@/utils";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      form: {
        id: "",
      },
    };
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "setMasterUserComissao",
          userID: String(this.form.id),
        };

        await request(payload).then((res) => {
          this.displayAlert(res.message);

          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    open(data) {
      this.dialog = true;

      setTimeout(() => {
        this.startForm(data);
      });
    },

    startForm(data) {
      this.$refs.id.handleInput(data);
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },

    displayAlert,

    onlyNumber,
  },
};
</script>

<style></style>
