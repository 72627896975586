<template>
  <v-container>
    <CurrenriesGrid class="mb-12" />

    <LevelsList class="mb-12" />

    <ConfigsCards />
  </v-container>
</template>

<script>
import CurrenriesGrid from "@/components/settings/currencies/CurrenriesGrid.vue";
import ConfigsCards from "@/components/settings/configs/ConfigsCards.vue";
import LevelsList from "@/components/settings/levels/LevelsList.vue";

export default {
  components: {
    CurrenriesGrid,
    ConfigsCards,
    LevelsList,
  },
};
</script>

<style></style>
