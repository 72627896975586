<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <div class="pa-6">
        <div class="d-flex mb-4">
          <span class="text-h6">Novo método de pagamento</span>

          <v-spacer />

          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-form v-model="valid" ref="form">
          <div class="mb-4">
            <custom-input
              ref="name"
              v-model="form.name"
              label="Nome do método"
            />

            <v-checkbox
              v-model="form.isCrypto"
              class="mt-0 pt-0"
              label="O pagamento se da através de criptomoeda"
            />

            <div v-if="form.isCrypto" class="d-flex flex-column">
              <custom-input
                ref="details"
                v-model="form.details"
                label="Hash para transação"
              />

              <custom-input
                ref="proofURL"
                v-model="form.proofURL"
                label="URl do comprovante"
              />
            </div>

            <custom-text-area
              v-else
              ref="details"
              label="Motivo da rejeição"
              placeholder="Detalhes de pagamento"
              v-model="form.details"
            />

            <custom-select
              ref="contractID"
              v-model="form.contractID"
              label="Contrato Vinculado"
              :items="_contracts"
            />
          </div>
        </v-form>

        <div class="d-flex justify-end">
          <v-btn class="text-none mr-2" plain @click="close()">Cancelar</v-btn>

          <v-btn color="primary" class="text-none" depressed @click="submit()">
            Confirmar
          </v-btn>
        </div>
      </div>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      currencyID: '',
      form: {
        name: "",
        isCrypto: false,
        details: "",
        proofURL: "",
        contractID: null,
      },
    };
  },

  props: {
    contracts: {
      type: Array,
      default: [],
    },
  },

  computed: {
    _contracts() {
      return [
        {
          label: "Nenhum contrato",
          value: "0",
        },
        ...this.contracts,
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "addNewRedeMoeda",
          moedaID: this.currencyID,
          nomeRede: this.form.name,
          infoRede: this.form.details,
          comprovanteLink: this.form.proofURL,
          forceContratoID: this.form.contractID,
          isCrypto: this.form.isCrypto ? "1" : "0",
        };

        await request(payload).then((res) => {
          this.displayAlert(res.message);

          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    open(data) {
      this.dialog = true;
      this.currencyID = data;

      setTimeout(() => {
        this.startForm();
      });
    },

    startForm() {
      this.form.isCrypto = false;
      this.$refs.name.handleInput("");
      this.$refs.details.handleInput("");
      this.$refs.contractID.handleInput(null);

      this.$refs.form.reset();
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },

    displayAlert,
  },
};
</script>

<style lang="scss" scoped></style>
